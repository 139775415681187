<template>
  <div>
    <div
      v-permission="'classroom_configs.create'"
      class="flex flex-wrap-reverse items-center">
      <div
        class="p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary"
        @click="addNew">
        <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
        <span class="ml-2 text-base text-primary">{{ $t('AddNew') }}</span>
      </div>
    </div>
    <Grid
      :service="service"
      route_name="classroom_configs"
      order_column="created_at">
      <template v-slot:gridThead>
        <vs-th key="id" sort-key="id">ID</vs-th>
        <vs-th key="name" sort-key="name">{{ $t('classroom.name') }}</vs-th>
        <vs-th key="institution_name" sort-key="institution_name" v-if="isAdmin()">{{ $t('classroom.institution') }}</vs-th>
        <vs-th key="total" sort-key="total">{{ $t('classroom.total') }}</vs-th>
        <vs-th key="quantity_per_room" sort-key="quantity_per_room">{{ $t('classroom.quantity_per_room') }}</vs-th>
        <vs-th key="quantity_total">{{ $t('classroom.quantity_total') }}</vs-th>
      </template>
      <template v-slot:gridData="props">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
          <vs-td width="5%" key="id">
            <div> {{ tr.id }} </div>
          </vs-td>
          <vs-td width="25%" key="name">
            <span> {{ tr.name }}</span>
          </vs-td>
          <vs-td width="20%" key="institution_name" v-if="isAdmin()">
            <span> {{ tr.institution.name }}</span>
          </vs-td>
          <vs-td width="10%" key="total">
            <span> {{ tr.total }}</span>
          </vs-td>
          <vs-td width="15%" key="quantity_per_room">
            <span> {{ tr.quantity_per_room }}</span>
          </vs-td>
          <vs-td width="15%" key="quantity_total">
            <span> {{ tr.quantity_total }}</span>
          </vs-td>
          <vs-td width="10%">
            <span class="flex flex-row justify-center gap-1">
              <!--
              <feather-icon
                v-permission="'classroom_configs.show'"
                icon="EyeIcon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-success"
                @click="detail(props.data[indextr].id)"
                v-tooltip.top="{content: 'Detalhar',delay: { show: 1000, hide: 100 }}"/>
              -->
              <feather-icon
                v-permission="'classroom_configs.edit'"
                icon="EditIcon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-warning"
                @click="props.edit(props.data[indextr].id)"/>
              <feather-icon
                v-permission="'classroom_configs.delete'"
                icon="Trash2Icon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-danger"
                @click="props.destroy(props.data[indextr])"/>
            </span>
          </vs-td>
        </vs-tr>
      </template>
    </Grid>
  </div>
</template>

<script>
import { isAdmin } from '@/util/Util'

import ClassroomConfigService from '@/services/api/ClassroomConfigService'

export default {
  data: () => ({
    selected: [],
    service: null
  }),
  methods: {
    addNew() {
      this.$router.push('/classroom_configs/create')
    },
    detail(id) {
      this.$router.push(`/classroom_configs/${id}/detail`)
    },
    isAdmin() {
      return isAdmin()
    },
  },
  beforeMount() {
    this.service = ClassroomConfigService.build(this.$vs)
  }
}
</script>
