var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "permission",
              rawName: "v-permission",
              value: "classroom_configs.create",
              expression: "'classroom_configs.create'",
            },
          ],
          staticClass: "flex flex-wrap-reverse items-center",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "p-3 mb-4 mr-4 rounded-lg cursor-pointer flex items-center justify-between text-lg font-medium text-base text-primary border border-solid border-primary",
              on: { click: _vm.addNew },
            },
            [
              _c("feather-icon", {
                attrs: { icon: "PlusIcon", svgClasses: "h-4 w-4" },
              }),
              _c("span", { staticClass: "ml-2 text-base text-primary" }, [
                _vm._v(_vm._s(_vm.$t("AddNew"))),
              ]),
            ],
            1
          ),
        ]
      ),
      _c("Grid", {
        attrs: {
          service: _vm.service,
          route_name: "classroom_configs",
          order_column: "created_at",
        },
        scopedSlots: _vm._u([
          {
            key: "gridThead",
            fn: function () {
              return [
                _c("vs-th", { key: "id", attrs: { "sort-key": "id" } }, [
                  _vm._v("ID"),
                ]),
                _c("vs-th", { key: "name", attrs: { "sort-key": "name" } }, [
                  _vm._v(_vm._s(_vm.$t("classroom.name"))),
                ]),
                _vm.isAdmin()
                  ? _c(
                      "vs-th",
                      {
                        key: "institution_name",
                        attrs: { "sort-key": "institution_name" },
                      },
                      [_vm._v(_vm._s(_vm.$t("classroom.institution")))]
                    )
                  : _vm._e(),
                _c("vs-th", { key: "total", attrs: { "sort-key": "total" } }, [
                  _vm._v(_vm._s(_vm.$t("classroom.total"))),
                ]),
                _c(
                  "vs-th",
                  {
                    key: "quantity_per_room",
                    attrs: { "sort-key": "quantity_per_room" },
                  },
                  [_vm._v(_vm._s(_vm.$t("classroom.quantity_per_room")))]
                ),
                _c("vs-th", { key: "quantity_total" }, [
                  _vm._v(_vm._s(_vm.$t("classroom.quantity_total"))),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "gridData",
            fn: function (props) {
              return _vm._l(props.data, function (tr, indextr) {
                return _c(
                  "vs-tr",
                  { key: indextr, attrs: { data: tr } },
                  [
                    _c("vs-td", { key: "id", attrs: { width: "5%" } }, [
                      _c("div", [_vm._v(" " + _vm._s(tr.id) + " ")]),
                    ]),
                    _c("vs-td", { key: "name", attrs: { width: "25%" } }, [
                      _c("span", [_vm._v(" " + _vm._s(tr.name))]),
                    ]),
                    _vm.isAdmin()
                      ? _c(
                          "vs-td",
                          { key: "institution_name", attrs: { width: "20%" } },
                          [
                            _c("span", [
                              _vm._v(" " + _vm._s(tr.institution.name)),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _c("vs-td", { key: "total", attrs: { width: "10%" } }, [
                      _c("span", [_vm._v(" " + _vm._s(tr.total))]),
                    ]),
                    _c(
                      "vs-td",
                      { key: "quantity_per_room", attrs: { width: "15%" } },
                      [_c("span", [_vm._v(" " + _vm._s(tr.quantity_per_room))])]
                    ),
                    _c(
                      "vs-td",
                      { key: "quantity_total", attrs: { width: "15%" } },
                      [_c("span", [_vm._v(" " + _vm._s(tr.quantity_total))])]
                    ),
                    _c("vs-td", { attrs: { width: "10%" } }, [
                      _c(
                        "span",
                        { staticClass: "flex flex-row justify-center gap-1" },
                        [
                          _c("feather-icon", {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: "classroom_configs.edit",
                                expression: "'classroom_configs.edit'",
                              },
                            ],
                            attrs: {
                              icon: "EditIcon",
                              svgClasses:
                                "h-5 w-5 mb-1 mr-3 stroke-current text-warning",
                            },
                            on: {
                              click: function ($event) {
                                return props.edit(props.data[indextr].id)
                              },
                            },
                          }),
                          _c("feather-icon", {
                            directives: [
                              {
                                name: "permission",
                                rawName: "v-permission",
                                value: "classroom_configs.delete",
                                expression: "'classroom_configs.delete'",
                              },
                            ],
                            attrs: {
                              icon: "Trash2Icon",
                              svgClasses:
                                "h-5 w-5 mb-1 mr-3 stroke-current text-danger",
                            },
                            on: {
                              click: function ($event) {
                                return props.destroy(props.data[indextr])
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              })
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }